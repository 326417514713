import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      isFooter: true,
    },
  },
  // {
  //   path: "/recreation",
  //   name: "recreation",
  //   component: () =>
  //     import("../views/recreation.vue"),
  //   meta: {
  //     isFooter: true,
  //   },
  // },
  
 
  
  
];

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function  push(to) {
  return VueRouterPush.call(this,to).catch(err => err)
}

const router = new VueRouter({
  mode: "hash",//原先history  hash
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 如果有 savedPosition，则恢复到之前的滚动位置
    if (savedPosition) {
      return savedPosition
    } else {
      // 否则滚动到页面顶部
      return { x: 0, y: 0 }
    }
  }
});
router.beforeEach((to, from, next) => {
  // 如果是前进路由，则滚动到页面顶部
  if (router.app.$route && to.path !== router.app.$route.path) {
    const appId = document.querySelector("#app")
    // if(to.path != '/classIndex'){
      if(appId){
        appId.scrollTo(0,0)
      }
    // }

    
    // window.scrollTo(0, 0);
  }
  next();
});
export default router;
