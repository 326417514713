import { userInfo1 } from '@/request/api'
export default {
	namespaced: true, // 使用命名空间
	state: {
		user: {},
		sportConfig: [
			{
				id: 0,
				show: false,
				icon: 'pankou',
				key: 'colorMode',
				name: '颜色模式',
				active: 1,
				list: ["日间版", "夜间版"],
			},
			{
				id: 1,
				show: true,
				icon: 'pankou',
				key: 'pankou',
				name: '盘口设置',
				width: '50%',
				active: 0,
				list: [
					'欧洲盘', '香港盘', '马来盘', '印尼盘', '美式盘'
				]
			},
			{
				id: 2,
				show: true,
				icon: 'yemian',
				key: 'pankouShow',
				name: '页面设计',
				width: '50%',
				active: 1,
				list: [
					'横向显示', '纵向显示'
				]
			},
			{
				id: 3,
				show: true,
				icon: 'peilv',
				key: 'peilv',
				name: '赔率偏好',
				active: 1,
				list: [
					'不接受变动', '自动接收变动', '接受变动',
				],
			},
			{
				id: 4,
				show: true,
				icon: 'tiqian',
				key: 'jiesuan',
				name: '提前结算偏好',
				active: 0,
				list: [
					'接受变化', '不接受变化'
				],
			},
			{
				id: 5,
				show: false,
				icon: 'yuyue',
				key: 'koukuan',
				name: '预约扣款设置',
				active: 0,
				list: [
					'接受预约扣款', '不接受预约扣款'
				],
			},
			{
				id: 6,
				show: true,
				icon: 'shengjiang',
				key: 'shengjiang',
				name: '升降颜色',
				type: 'color',
				active: 0,
				list: [
					[{
							icon: 'arrow-up',
							name: '红升',
							color: '#FF3B30'
						},
						{
							icon: 'arrow-down',
							name: '绿降',
							color: '#34C759'
						}
					],
					[{
							icon: 'arrow-up',
							name: '绿升',
							color: '#34C759'
						},
						{
							icon: 'arrow-down',
							name: '红降',
							color: '#FF3B30'
						},

					],
				],
			},
			{
				id: 7,
				show: false,
				key: 'paixu',
				name: '赛事排序',
				wrap: true,
				active: 1,
				list: [
					'时间排序', '联赛排序'
				],
			},
			{
				id: 8,
				show: true,
				key: 'qita',
				name: '其他',
				wrap: true,
				type: 'url',
				list: [{
						name: '投注教程',
						url: '/jiaocheng',
						icon: 'jiaocheng'
					},
					{
						name: '赛事公告',
						// url: '/gonggao',
						url: '/jiaocheng',
						icon: 'saishi'
					},
					{
						name: '赛果',
						// url: '/saiguo',
						url: '/jiaocheng',
						icon: 'saiguo'
					},
					{
						name: '体育规则',
						// url: '/guize',
						url: '/jiaocheng',
						icon: 'tiyu'
					},
					{
						name: '模拟投注',
						// url: '/moni',
						url: '/jiaocheng',
						icon: 'moni'
					}
				],
			},
		],
		sjcolor: {
			up: 'red',
			down: 'green'
		},
		scheme: ['dark', 'light'],
		theme: 'light',
		// 深色主题
		dark: {
			'--243B4F': '#243B4F',
			'--15212d': '#15212d',
			'--202C37': '#202C37',
			'--B3B9D2': '#B3B9D2',
			'--193146': '#304b62',
			'--theme-text': "#4774DA",
			'--right-slider-li-border': '#283e4c80',
			'--bg-color': '#193045',
			// '--bg-color': '#15212D',
			'--slider-bg': '#B3B9D2',
			'--calc-border': '#283E4C',
			'--theme-white': '#202C37',
			'--grid-bg': 'linear-gradient(180deg, #3e5264 0%, #283643 100%)',
			'--0019314d': '#0019314d',
			'--304b62': '#304b62',

			'--sportdefault-bg': '#ffffff0a',
			'--theme-bg': "#362911",
			'--left-slider-block': '#151618',
			'--font-color': '#fff',
			'--right-slider-bg': '#000',
			'--theme-summary-title': '#fff',
			'--theme-summary': '#B3B9D2',
			'--gujia-bg': '#304b62',
			'--theme-button-bg': '#000',
			'--theme-tab-label': '#718096',
			'--tijianjiesuan': '#1c1d1f',
			'--time-list': '#000',
			'--type-font-active': '#fff',
			'--index-font-color': '#B3B9D2',
			'--time-list-border': '#2b2c30',
			// '--match-list-header': 'linear-gradient(360deg,#000 10.57%,#151618 90%)',
			'--match-list-header': '#1a2531',
			'--bottom-popup-bg': '#151618',
			'--order-bg': '#000',
			'--details-item': '#2b2c30',
			'--theme-top-icon': '#5a6375',
		},
		// 浅色主题
		light: {
			'--theme-bg': "#362911",
			'--theme-text': "#B1873B",
			'--theme-white': '#fff',
			'--bg-color': '#f0f3f6',
			'--left-slider-block': '#fff',
			'--font-color': '#000',
			'--right-slider-bg': '#f0f3f6',
			'--theme-summary-title': '#8d9198',
			'--theme-summary': '#131a3e',
			'--gujia-bg': '#e9e9e9',
			'--right-slider-li-border': '#eaedf6',
			'--theme-button-bg': '#f4f4f4',
			'--theme-tab-label': '#aeb4bd',
			'--slider-bg': '#272c46',
			'--tijianjiesuan': '#f7f7f7',
			'--time-list': '#fff',
			'--type-font-active': '#000',
			'--index-font-color': '#313c55',
			'--time-list-border': '#eaedf6',
			'--match-list-header': 'linear-gradient(360deg,#fbfbfb 33.57%,#fff)',
			'--bottom-popup-bg': '#fff',
			'--order-bg': '#f4f4f4',
			'--calc-border': '#edeef1',
			'--grid-bg': '#fff',
			'--theme-top-icon': '#c8c8c8',
		}

	},
	mutations: {
		setUserInfo(state, obj) {
			state.user = obj
		},
		getStaateConfig() {
			// if (localStorage.getItem("sportConfig")) {
			// 	let resobj = JSON.parse(localStorage.getItem("sportConfig"))
			// 	resobj.forEach((item,i) => {
			// 		item.show = state.sportConfig[i].show
			// 	})
			// 	state.sportConfig = resobj
			// }
		},
		setSportConfig(state, {
			index,
			active
		}) {
			console.log(state, {
				index,
				active
			});

			if (index == 6) {
				if (state.sportConfig[6].active) {
					state.sjcolor = {
						up: 'red',
						down: 'green'
					}
				} else {
					state.sjcolor = {
						up: 'green',
						down: 'red'
					}
				}
			}

			// if (index==7) {

			// }
			state.sportConfig[index].active = active

			localStorage.setItem("sportConfig", JSON.stringify(state.sportConfig))
		},
	},
	actions: {
		getUserInfo({commit}) {
			return new Promise(resolve => {
				userInfo1().then((res) => {
					if (res.code == 0) {
						commit('setUserInfo', res.data || {})
						resolve()
					}
				})
			})
		},
	},
	getters: {
		$theme(state) {
			state.theme = state.sportConfig[0].active ? 'dark' : 'light'
			return state[state.theme]
		},
	}
};