<template>
  <div class="home">

    <div class="frist" id="oneDiv">
     
          <div class="leftIcon animate__animated  animate__fadeInDownBig" @click="showPop = true"><img src="@/assets/icon/stg.webp" /></div>
  
        <div class="rightIcon animate__animated animate__fadeInDownBig" @click="$store.commit('goService')"><img src="@/assets/icon/erj.webp" /></div>

        <div class="threej divInImg animate__animated animate__jackInTheBox"><img src="@/assets/threej.webp" /></div>

      <div class="oneTitle animate__animated animate__fadeInLeft">
        星光会
      </div>
      <div class="oneTips animate__animated animate__fadeInRight">体育赛事专业分析</div>

      
      <div class="dibu animate__animated animate__fadeInUpBig" >
        <div class="ljwm">
          <div>国际化的体育比赛专业分析</div>
          <div>了解我们</div>
        </div>
        <div class="xjt divInImg" @click="goTwo"><img src="@/assets/twoBomJt.webp" /></div>
      </div>
 
    </div>


      <div class="second"  id="twoDiv">
        <div class="trapezoid wow animate__animated animate__fadeInUpBig">
          <div class="jb divInImg"><img src="@/assets/jb.webp" /></div>
          <span>主打联赛</span>
        </div>
        <div class="secondCont wow animate__animated animate__jackInTheBox">

          <div class="secondList wow animate__animated animate__jackInTheBox" v-for="(item,index) in bsList" :key="index">
            <div class="secondLeft"><img :src="require(`@/assets/bs${index}.webp`)" :class="{bs0:index == 0,bs1:index == 1,bs2:index == 2,bs3:index == 3,}" /></div>
            <div class="secondRight">{{ item.content }}</div>
          </div>
        </div>
      </div>


    <div class="demoOut"  id="threeDiv">
      <div class="titleLine wow animate__animated animate__fadeInRight">
        <div class="titLeft">
          <img src="@/assets/title1.webp" />
        </div>
        预测结果输出
      </div>
    
      <div class="tabTit wow animate__animated animate__fadeInDown" key="0">
        <div class="tabTitles tab0">
          赛事名称
        </div>
        <div class="tabTitles tabGreen tab1">
          预测结果
        </div>
        <div class="tabTitles tabGreen tab2">
          预测概率
        </div>
        <div class="tabTitles tab3">
          关键因素
        </div>
      </div>
     
        <div class="tabTr wow animate__animated animate__fadeInDown" v-for="(item, index) in tabList" :key="index+1">
          <div class="tabTd tab0">
            {{ item.td0 }}
          </div>
          <div class="tabTd tab1">
            {{ item.td1 }}
          </div>
          <div class="tabTd tab2">
            {{ item.td2 }}
          </div>
          <div class="tabTd tab3">
            {{ item.td3 }}
          </div>
        </div>
 

    </div>


    <div class="demoOut"  id="fourDiv">
      <div class="titleLine wow animate__animated animate__lightSpeedInLeft">
        <div class="titLeft">
          <img src="@/assets/title2.webp" />
        </div>
        星光会的简介
      </div>
      <div class="Intro  wow animate__animated animate__rotateInUpLeft" v-for="(item, index) in IntroList" :key="index">
        <div class="introLeft divInImg">
          <img :src="require(`@/assets/four${index}.webp`)" />
        </div>
        <div class="introRight">
          <div class="introTit">{{ item.title }}</div>
          <div class="introCont">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <div class="demoOut"  id="fiveDiv">
      <div class="titleLine wow animate__animated animate__lightSpeedInLeft">
        <div class="titLeft">
          <img src="@/assets/title1.webp" />
        </div>
        专业分析优势
      </div>
      <div class="fiveItem wow animate__animated animate__zoomInLeft" v-for="(item, index) in fiveList" :key="index">
        <div class="fiveLeft"><img :src="require(`@/assets/${index + 1}.webp`)" /></div>
        <div class="fiveRight">
          <div class="fiveTitle">{{ item.title }}</div>
          <div class="fiveContent">{{ item.content }}</div>
        </div>
      </div>
    </div>


    <div class="demoOut"  id="sixDiv">
      <div class="titleLine wow animate__animated animate__rotateInUpLeft">
        <div class="titLeft">
          <img src="@/assets/title1.webp" />
        </div>
        数据收集
      </div>
      <div class="fiveItem wow animate__animated animate__jackInTheBox" v-for="(item, index) in fiveList1" :key="index">
        <div class="fiveLeft"><img :src="require(`@/assets/${index + 1}.webp`)" /></div>
        <div class="fiveRight">
          <div class="fiveTitle">{{ item.title }}</div>
          <div class="fiveContent">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <div class="demoOut"  id="sevenDiv">
      <div class="titleLine  wow animate__animated animate__fadeInTopLeft">
        <div class="titLeft">
          <img src="@/assets/title1.webp" />
        </div>
        数据清洗与预处理
      </div>
      <div class="sevenItem wow animate__animated animate__fadeInTopRight" v-for="(item, index) in fiveList2" :key="index" :class="{duan:index == 1}">
        <div class="sevenTitle">
          <div class="sevenJt divInImg"><img src="@/assets/Polygon_1.webp" /></div>
          {{ item.title }}</div>
        <div class="sevenContent">{{ item.content }}</div>
        
      </div>
    </div>

    <div class="demoOut"  id="enghtDiv">
      <div class="titleLine  wow animate__animated animate__backInDown">
        <div class="titLeft">
          <img src="@/assets/title1.webp" />
        </div>
        分析流程
      </div>
      <div class="ensItem" >
        <div class="ensLeft  wow animate__animated animate__zoomInDown"></div>
        <div class="ensRight">
          <div class="ensRiItem wow animate__animated animate__backInDown" v-for="(item,index) in fiveList3" :key="index">
            <div class="ensTit">{{ item.title }}</div>
            <div class="ensCont">{{ item.content }}</div>
          </div>
          
        </div>
      </div>
    </div>

    <div class="demoOut"  id="ninDiv">
      <div class="titleLine wow animate__animated animate__fadeInBottomLeft">
        <div class="titLeft">
          <img src="@/assets/title1.webp" />
        </div>
        会员专享福利
      </div>
      <div class="fiveItem wow animate__animated animate__zoomInRight" v-for="(item, index) in fiveList4" :key="index">
        <div class="fiveLeft"><img :src="require(`@/assets/${index + 1}.webp`)" /></div>
        <div class="fiveRight">
          <div class="fiveTitle">{{ item.title }}</div>
          <div class="fiveContent">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <div class="demoOut"  id="tenDiv">
      <div class="titleLine  wow animate__animated animate__slideInDown">
        <div class="titLeft">
          <img src="@/assets/title1.webp" />
        </div>
        为什么选择星光会？
      </div>
      <div class="nineItem wow animate__animated animate__slideInDown" v-for="(item,index) in fiveList5" :key="index">
        <div class="nineTop">
          <div class="nineLeft"><img :src="require(`@/assets/${index + 1}.webp`)" /></div>
          <div class="nineRight">{{ item.title }}</div>
        </div>
        <div class="nineBom">{{ item.content }}</div>
      </div>
    </div>

    <div class="demoOut"  id="elevenDiv">
      <div class="titleLine wow animate__animated animate__zoomIn">
        <div class="titLeft">
          <img src="@/assets/title1.webp" />
        </div>
        常见问题
      </div>
      <div class="oneZeroItem"  v-for="(item,index) in fiveList6" :key="index">
        <div class="oneZeroTop  wow animate__animated animate__zoomInLeft">
          {{ item.title }}
        </div>
        <div class="oneZeroBom  wow animate__animated animate__zoomIn">
          {{ item.content }}
        </div>
      </div>
    </div>

    <div class="atLast" id="twoenDiv">
      <div class="lastTit wow animate__animated animate__jackInTheBox">立即加入星光会</div>
      <van-button class="mfzx  wow animate__animated animate__zoomInUp"  @click="$store.commit('goService')">免费咨询</van-button>
    </div>

    <van-popup v-model="showPop" class="typePop" position="left">
      <div class="particulcss">
        <div class="tiaot"></div>
        <div class="titLine">
        
          <div class="titConter">目录</div>
          <div class="titClose divInImg" @click="showPop = false"><img src="@/assets/close.webp" /></div>
        </div>
        <div class="listOut">
          <div class="lisDemo" :class="{activeItem: active == item.id }" v-for="(item,index) in myList" :key="index" @click="scrollToElement(item)">
            <div class="dian"></div>{{ item.name }}
          </div>
        </div>
        
      </div>
    </van-popup>

  </div>
</template>

<script>
import 'animate.css';
export default {
  name: "HomeView",
  components: {

  },
  data() {
    return {
      showPop:false,
      active:0,
      bsList:[{
        content:"NBA：我们对NBA赛事进行了深入研究，分析球队实力、球员数据、战术策略等关键因素，提供精准的比赛预测。",
      },{
        content:"五大联赛：英超、西甲、意甲、德甲、法甲等欧洲五大联赛是我们的重点关注领域，为您的足球投注提供专业指导。",
      },{
        content:"欧冠：作为欧洲俱乐部足球最高水平的赛事，我们提供全面的分析，帮助您把握比赛的走向，做出明智的投注决定。",
      },{
        content:"其他联赛：我们还涵盖日足联、韩K、澳超以及欧洲其他顶级赛事，提供多样的赛事分析，满足您的各种投注需求。",
      },
    ],
      tabList: [{
        td0: "NBA:勇士(-4.5)vs湖人(+4.5)",
        td1: "勇士胜",
        td2: "72%",
        td3: "勇士近期状态出色，湖人伤病较多",
      }, {
        td0: "英超:曼联(-0.5)vs利物浦(+0.5)",
        td1: "利物浦胜",
        td2: "81%",
        td3: "利物浦进攻火力强劲，曼联防守薄弱",
      }, {
        td0: "欧冠:皇马(0)vs拜仁(0)",
        td1: "皇马胜",
        td2: "68%",
        td3: "皇马主场优势明显，拜仁近期状态不佳",
      },
      ],
      IntroList: [
        {
          title: "国际化团队",
          content: "星光会由八位来自世界各地的顶尖精算师组成他们拥有丰富的经验和专业技能，精通各种体育赛事，为您的决策提供多维度的视角",
        },
        {
          title: "精通体育博彩",
          content: "团队成员深音体育博彩行业规则，掌握各个联赛的特色，洞察比赛的细微变化，帮助您做出明智的投注选择。",
        },
        {
          title: "人工智能分析",
          content: "星光会研发的人工智能AI分析系统，拥有海量赛事数据，结合深度学习算法，为您提供精准的比赛预测和投注建议。",
        },
      ],
      fiveList: [
        {
          title: "数据驱动分析",
          content: "我们基于海量数据进行分析，并结合先进的统计模型，提供客观的赛事预测，降低主观臆断的影响，提高预测准确率。",
        },
        {
          title: "深度赛事洞察",
          content: "我们的团队成员不仅精通比赛规则，还深入研究球队战术、球员状态、伤病情况等关键信息，为预测提供全面的分析支撑。",
        },
        {
          title: "市场趋势把握",
          content: "我们密切关注市场趋势，分析赔率和盘口的变化，结合历史数据和模型预测，为您的投注策略提供参考依据。",
        }, {
          title: "个性化建议",
          content: "根据您的需求和风险偏好，我们提供个性化的投注建议和策略，帮助您制定合理的投资计划，提高盈利可能性。",
        },
      ],
      fiveList1: [
        {
          title: "实时数据采集",
          content: "我们通过多种渠道实时采集赛场上的变化，时间，教练部署等关键信息，确保数据的及时性和准确性。",
        },
        {
          title: "比赛信息收集",
          content: "我们收集各联赛的比赛信息，包括球队阵容、球员状态、伤病情况、战术策略等，为分析提供多维度的参考依据。",
        },
        {
          title: "市场数据收集",
          content: "我们收集赔率、盘口、交易量等市场数据，分析市场趋势和投注者的行为，为预测提供更全面的参考信息。",
        },
      ],
      fiveList2: [
        {
          title: "数据清洗与预处理",
          content: "我们对收集到的原始数据进行严格的清洗和预处理，去除异常值、重复数据，确保数据的质量和可靠性。这项工作是保证预测准确率的关键步骤。",
        },
        {
          title: "数据转换与格式化",
          content: "将数据转换为统一的格式，便于分析和建模。同时，对一些关键数据进行标准化处理，提高数据的一致性和可比性。",
        },
        {
          title: "数据特征工程",
          content: "对数据进行特征工程，提取关键特征，提高模型的预测能力。这包括对原始数据进行变换、组合和筛选，构建更有效的模型输入。",
        },
      ],
      fiveList3: [
        {
          title: "数据收集",
          content: "全面收集赛事、球队和球员的历史数据。",
        },
        {
          title: "模型分析",
          content: "运用专业模型，对数据进行深度分析和处理。",
        },
        {
          title: "市场对比",
          content: "结合当前市场盘口，进行综合评估。",
        },{
          title: "策略制定",
          content: "根据分析结果，为会员提供最佳投注策略。",
        },
      ],
      fiveList4: [
        {
          title: "每日赛事分析",
          content: "获取精算师团队每日精心制作的赛事分析报告。报告包含详细的数据分析、战术解读和投注建议。",
        },
        {
          title: "实时咨询服务",
          content: "享受与精算师一对一的实时咨询服务。随时解答您的疑问，为您制定个性化投注策略。",
        },
        {
          title: "高级数据工具",
          content: "使用我们开发的专业数据分析工具。轻松掌握市场动态，做出更明智的投注决策。",
        },],
        fiveList5: [
        {
          title: "专业团队",
          content: "八名世界顶级精算师，确保分析的权威性和准确性。",
        },
        {
          title: "每日更新",
          content: "每天提供最新的赛事分析，助您把握最佳投注时机。",
        },
        {
          title: "定制服务",
          content: "根据您的需求，提供个性化的投注建议和策略。",
        },
        {
          title: "利润分成",
          content: "只有在您盈利时，我们才收取2成分成，利益与您完全一致。",
        },
      ],
      fiveList6: [
        {
          title: "星光会的精算师真的那么厉害吗？",
          content: "是的，我们的精算师都是经过严格筛选的行业顶尖人才。他们拥有多年的实战经验和深厚的专业知识。在体育博彩领域，他们的分析能力远超普通玩家。",
        },
        {
          title: "如何保证分析的准确性？",
          content: "我们采用先进的数学模型和大数据分析技术，结合精算师的专业判断，以确保分析的准确性。同时，我们会持续追踪和评估每次预测的结果，不断优化我们的分析方法。",
        },
        {
          title: "2成的利润分成是否太高？",
          content: "我们的分成模式是建立在您盈利的基础上的。这意味着只有当您获得收益时，我们才会收取分成。这种模式确保了我们的利益与您的利益完全一致，激励我们提供最优质的服务。",
        },
      ],
      myList:[{
        id:1,
        name:"主打联赛",
        link:"twoDiv",
      },{id:2,
        name:"预测结果输出",
        link:"threeDiv",
      },{id:3,
        name:"星光会的简介",link:"fourDiv",
      },{id:4,
        name:"专业分析优势",link:"fiveDiv",
      },{id:5,
        name:"数据收集",link:"sixDiv",
      },{id:6,
        name:"数据清洗与预处理",link:"sevenDiv",
      },{id:7,
        name:"分析流程",link:"enghtDiv",
      },{id:8,
        name:"会员专享福利",link:"ninDiv",
      },{id:9,
        name:"为什么选择星光会？",link:"tenDiv",
      },{id:10,
        name:"常见问题",link:"elevenDiv",
      },{id:11,
        name:"联系我们",link:"twoenDiv",
      },
    ],
    oneDiv:'',
    twoDiv:'',
    threeDiv:'',
    fourDiv:'',
    fiveDiv:'',
    sixDiv:'',
    sevenDiv:'',
    enghtDiv:'',
    ninDiv:'',
    tenDiv:'',
    elevenDiv:'',
    twoenDiv:'',
    bodyHeight:'',
    };
  },
  watch: {
    bodyHeight:{
      handler(newVal){
        if(!newVal){
          this.active = 0
          return
        }
        if(newVal >= this.oneDiv){
          this.active = 0
        }
        if(newVal >= this.twoDiv){
          this.active = 1
        }
        if(newVal >= this.threeDiv){
          this.active = 2
        }
        if(newVal >= this.fourDiv){
          this.active = 3
        }
        if(newVal >= this.fiveDiv){
          this.active = 4
        }
        if(newVal >= this.sixDiv){
          this.active = 5
        }
        if(newVal >= this.sevenDiv){
          this.active = 6
        }
        if(newVal >= this.enghtDiv){
          this.active = 7
        }
        if(newVal >= this.ninDiv){
          this.active = 8
        }
        if(newVal >= this.tenDiv){
          this.active = 9
        }
        if(newVal >= this.elevenDiv){
          this.active = 10
        }
        if(newVal >= this.twoenDiv){
          this.active = 11
        }
        
      },
      immediate:true,
      deep:true,
    }
  },
  mounted() {
    window.addEventListener("scroll",this.windowScroll)
    if (this.$route.query.code !== undefined) {
      localStorage.setItem("myCode", this.$route.query.code);
    }
    if (this.$route.query.url !== undefined) {
      localStorage.setItem("inUrl", this.$route.query.url);
    }
    this.$nextTick(()=>{
      this.$wow.init()
      this.oneDiv = document.getElementById('oneDiv').offsetTop
      this.twoDiv = document.getElementById('twoDiv').offsetTop
      this.threeDiv = document.getElementById('threeDiv').offsetTop
      this.fourDiv = document.getElementById('fourDiv').offsetTop
      this.fiveDiv = document.getElementById('fiveDiv').offsetTop
      this.sixDiv = document.getElementById('sixDiv').offsetTop
      this.sevenDiv = document.getElementById('sevenDiv').offsetTop
      this.enghtDiv = document.getElementById('enghtDiv').offsetTop
      this.ninDiv = document.getElementById('ninDiv').offsetTop
      this.tenDiv = document.getElementById('tenDiv').offsetTop
      this.elevenDiv = document.getElementById('elevenDiv').offsetTop
      this.twoenDiv = document.getElementById('twoenDiv').offsetTop
    })
  },

  methods: {
    goTwo(){
      const element = document.getElementById('twoDiv');
      if (element) {
        // 获取目标元素的位置
        const top = element.getBoundingClientRect().top + window.pageYOffset;
        // 平滑滚动到目标位置
        window.scrollTo({ top:top,left:0, behavior: 'smooth' });
      }
     
      
    },
    scrollToElement(item) {
      this.showPop = false;
      // 通过 id 获取目标元素
      const element = document.getElementById(item.link);
      if (element) {
        // 使用 scrollIntoView 方法滚动到目标元素
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    windowScroll(){
      this.bodyHeight = document.documentElement.scrollTop || document.body.scrollTop
    },

  },
  beforeDestroy() {

  },
};
</script>
<style lang="less" scoped>
.home {
  background: rgba(4, 9, 10, 1);
  padding-bottom: 79px;
  .frist {
    width: 100%;
    min-height: 100dvh;
    background: radial-gradient(63.81% 90.18% at 100% 0%, rgba(60, 142, 91, 1) 0%, rgba(35, 83, 53, 0) 100%);
    padding-top: 40%;

    .leftIcon {
      width: 44px;
      height: 44px;
      border-radius: 33px;
      background: rgba(255, 255, 255, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 10px;
      left: 10px;
      z-index: 50;
      img {
        width: 24px;
        height: 24px;
      }
    }

    .rightIcon {
      width: 44px;
      height: 44px;
      border-radius: 33px;
      background: rgba(255, 255, 255, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
      img {
        width: 24px;
        height: 24px;
      }
    }

    .threej {
      width: 196px;
      height: 111px;
      margin: 0 auto;
    }

    .oneTitle {
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 36px;
      line-height: 50px;
      margin-top: 15px;
      letter-spacing: 12px;
      text-indent: 4px;
    }

    .oneTips {
      text-align: center;
      color: rgba(56, 175, 102, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 6px;
      margin-top: 4px;
    }

    .dibu {
      position: absolute;
      bottom: 10px;
      text-align: center;
      left: 0;
      right: 0;
      margin: auto;

      .ljwm {
        color: rgba(0, 165, 130, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 4px;
      }

      .xjt {
        width: 24px;
        height: 24px;
        margin: 0 auto;
      }
    }
  }

  .second {
    width: 100%;
    // min-height: 100dvh;
    padding: 30px 20px;
    background: linear-gradient(180deg, rgba(55, 137, 117, 0.16) 0%, rgba(14, 35, 30, 0.08) 100%);

    .trapezoid {
      width: 257px;
      height: 44px;
      background: url('@/assets/trapezoid.webp') no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .jb {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }

      span {
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 600;
        font-size: 24px;

      }
    }

    .secondCont {
      background: url('@/assets/bigBj.webp') no-repeat;
      background-size: 100% 100%;
      position: relative;
      width: 335px;
      padding: 20px;
      border-radius: 16px;
      
      box-sizing: border-box;
      overflow: hidden;
      z-index: 2;
      height: 522px;

      /* 确保伪元素不会超出边界 */
      .secondList {
        background: url('@/assets/bj1.webp') no-repeat;
        background-size: 100% 100%;
        padding: 10px;
        width: 100%;
        height: 113px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-bottom:10px;
        .secondLeft{
          width: 80px;
          height: 58px;
          background: linear-gradient(180deg, rgba(117, 255, 186, 1) 0%, rgba(94, 174, 134, 1) 100%);
          border-radius: 8px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .bs0{
            width: 60px;
  height: 60px;
          }
          .bs1{
            width: 50px;
            height: 50px;
          }
          .bs2{
            width: 68px;
  height: 45px;
          }
          .bs3{
            width: 34px;
            height: 41px;
          }
        }
        .secondRight{
          color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 600;
  font-size: 11px;  line-height: 16px;
  text-align: left;
        }
      }
    }


  }

  .demoOut {
    padding: 30px 20px;
    background: linear-gradient(180deg, rgba(55, 137, 117, 0.16) 0%, rgba(14, 35, 30, 0.08) 100%);
    width: 100%;

    .titleLine {
      width: 100%;
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 600;
      font-size: 24px;
      display: flex;
      align-items: center;

      .titLeft {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        justify-content: center;
        position: relative;
        background: rgba(138, 237, 200, 0.16);

        img {
          width: 32px;
          height: 32px;
        }
      }

      .titLeft::before {
        content: ' ';
        width: 4px;
        border-left: 4px solid rgba(255, 255, 255, 1);
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
    }
  }

  .tabTit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    height: 42px;
    margin-top: 20px;

    .tabTitles {
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 600;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      border-bottom: 2px solid rgba(102, 255, 159, 1);
    }

    .tabGreen {
      color: rgba(102, 255, 159, 1);
    }

  }

  .tabTr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex-shrink: 0;
    border-width: 2px;
    border-style: solid;
    border-image-source: linear-gradient(90deg, rgba(138, 237, 200, 0) 0%, rgba(108, 186, 157, 1) 50%, rgba(79, 135, 114, 0) 100%);
    border-image-slice: 1;
    box-sizing: border-box;

    .tabTd {
      gap: 16px;
      flex-shrink: 0;
      padding: 10px;
      background: linear-gradient(180deg, rgba(60, 142, 91, 0.2) 20.5%, rgba(6, 38, 31, 0.4) 100%);
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      height: 86px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .tab0 {
    width: 92px;
  }

  .tab1 {
    width: 68px;
  }

  .tab2 {
    width: 68px;
  }

  .tab3 {
    width: 92px;
  }

  .Intro {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
   
    width: 335px;
    padding: 10px;
    margin-top: 20px;
    gap: 16px;
    border-radius: 0px 12px 12px 0px;
    background: url('@/assets/bj2.webp') no-repeat;
    background-size: 100% 100%;
    .introLeft {
      width: 62px;
      height: 62px;
      flex-shrink: 0;
    }

    .introRight {
      width: 237px;

      .introTit {
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 600;
        font-size: 14px;
        text-align: left;
      }

      .introCont {
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        margin-top: 8px;
        line-height: 22px;
      }
    }
  }

  .fiveItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 124px;
    box-sizing: border-box;
    margin-top: 20px;
    
    .fiveLeft {
      flex-shrink: 0;
      padding: 10px;
      background: linear-gradient(180deg, rgba(60, 142, 91, 0.6) 20.5%, rgba(6, 38, 31, 0.55) 100%);
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 49px;
      }
    }

    .fiveRight {
      width: 295px;
      background: url('@/assets/bj3.webp') no-repeat;
      background-size: 100% 100%;
      padding: 10px;
      padding-top: 15px;
      height: 100%;
      border-radius: 0px 12px 12px 0px;

      .fiveTitle {
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
      }

      .fiveContent {
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        text-align: left;
        margin-top: 8px;
      }
    }
  }

  .sevenItem{
    padding: 10px;
  border-radius: 12px;

  box-sizing: border-box;
  background: url('@/assets/bj2.webp') no-repeat;
  background-size: 100% 100%;
  margin-top: 20px;
  .sevenTitle{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
    .sevenJt{
      width: 13px;
      height: 11px;
      margin-right: 3.1px;
    }
  }
  .sevenContent{
    color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin-top: 8px;
  text-align: left;
  }
  }
  .duan{
    background: url('@/assets/bj102.webp') no-repeat !important;
  background-size: 100% 100%!important;
  }
  .ensItem{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    .ensLeft{
      width: 21px;
      height: 356px;
      background: url(@/assets/shu.webp) no-repeat;
      background-size: 100% 100%;
    }
    .ensRight{
      .ensRiItem{
      width: 314px;
      height: 80px;
      padding: 15px 10px;
  border-radius: 0px 12px 12px 0px;
  
  box-sizing: border-box;
  background: url('@/assets/bj80.webp') no-repeat;
  background-size: 100% 100%;
  margin-bottom: 12px;
          .ensTit{
            color: rgba(255, 255, 255, 1);
            font-family: "PingFang SC";
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
          }
          .ensCont{
            color: rgba(255, 255, 255, 1);
            font-family: "PingFang SC";
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            margin-top: 8px;
            text-align: left;
          }
      }
    }
  }

  .nineItem{
    margin-top: 20px;
    .nineTop{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .nineLeft{
        width: 40px;
        height: 69px;
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        gap: 16px;
        flex-shrink: 0;
        padding: 10px;
        background: linear-gradient(180deg, rgba(60, 142, 91, 0.6) 20.5%, rgba(6, 38, 31, 0.55) 100%);
        img {
        width: 20px;
        height: 49px;
        flex-shrink: 0;
      }
      }
      .nineRight{
        width: 285.3px;
  height: 50px;
  display: inline-flex;
  place-content: flex-start;
  place-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  padding: 10px;
  border-radius: 0px 12px 12px 0px;

  box-sizing: border-box;
  background: url('@/assets/bj50.webp')no-repeat;
        background-size: 100% 100%;

  color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
      }
    }
    .nineBom{
      padding-left: 40px;
      height: 22px;
  color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: left;
  margin-top: 10px;
    }
  }

  .oneZeroItem{
    margin-top: 20px;
    width: 335px;
    .oneZeroTop{
      width: 100%;
      padding: 10px;
  border-radius: 12px;

      height: 50px;
  box-sizing: border-box;
      line-height: 50px;
  text-align: left;
  background: url('@/assets/bj5022.webp')no-repeat;
  background-size: 100% 100%;
  color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
    }
    .oneZeroBom{
      color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  padding: 0 10px;
  margin-top: 10px;
    }
  }

  .atLast{
    padding: 20px;
    .lastTit{
      text-align: center;
  color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
    }
    .mfzx{
      padding: 12px 28px;
  border-radius: 33px;
  background: linear-gradient(180deg, rgba(0, 142, 89, 1) 0%, rgba(0, 103, 74, 1) 100%);
  width: 335px;
  height: 48px;
  color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 600;
  font-size: 16px;
  border: 0;
  margin-top: 18px;
    }
  }
}

.divInImg {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }
}


.typePop {
  background: transparent;
  height: 100dvh;
  .particulcss {
    // height: calc(100dvh - 100px);
    height: 100dvh;
    
    width: 100%;
    // border-radius: 24px 24px 0px 0px;
    background: radial-gradient(159.08% 143.36% at 100% 0%, rgba(28, 78, 47, 1) 0%, rgba(4, 9, 10, 1) 100%);
    overflow: hidden;
    overflow-y: auto;
    padding: 0 20px 60px;
    .tiaot {
      width: 120px;
      height: 4px;
      flex-shrink: 0;
      border-radius: 2px;
      background: rgba(60, 142, 91, 0.3);
      margin: 10px auto 0 auto;
    }
    .detailTitle {
      margin-top: 40px;
      padding-left: 25px;
      text-align: left;
      color: #fff;
      text-overflow: ellipsis;
      font-family: "SF Pro Display";
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 36px */
    }
    .titTwo {
      padding-left: 25px;
      text-align: left;
      color: #fff;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 36px */
    }



    .titLine {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 15px auto 0 auto;
      .titClose {
        width: 44px;
        height: 44px;
      }
      .titConter {
        color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 600;
  font-size: 24px;
        line-height: 100%; /* 20px */
      }
    }
    .listOut{
      width: 100%;
      margin-top: 15px;
      .lisDemo{
        width: 100%;
        margin-bottom: 4px;
        height: 40px;
        padding-left: 10px;
        gap: 10px;
        flex-shrink: 0;
        background: rgba(255, 255, 255, 0.09);
        color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 600;
  font-size: 16px;
  text-align: left;
        line-height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .dian{
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background-color: #fff;
        }
      }
      .activeItem{
        color: rgba(102, 255, 159, 1)!important;
        .dian{
          background:rgba(102, 255, 159, 1)!important;
        }
      }
    }
  
  


    
   

    /deep/.van-picker__toolbar {
      display: none;
    }
    /deep/.van-picker {
      margin: 10px auto 15px auto;
      background-color: transparent;
      .van-picker__frame {
        background-color: rgba(143, 201, 255, 0.09);
      }
      .van-picker__mask {
        background: transparent;
      }
      .van-hairline-unset--top-bottom::after {
        border-width: 0;
      }
      .van-ellipsis {
        color: #fff;
      }
      .van-picker-column__item--selected {
        position: relative;
        z-index: 5;
      }
    }
  }
}

.v-enter-active {
  transition: all 0.75s ease;
  animation: left1 0.75s linear;
}

.top-enter-active {
  transition: all 0.75s ease;
  animation: top1 0.75s linear;
}
.right1-enter-active {
  transition: all 0.75s ease;
  animation: right1 0.75s linear;
}
.bottom1-enter-active {
  transition: all 0.75s ease;
  animation: bottom1 0.75s linear;
}

@keyframes left1 {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes top1 {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes right1 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes bottom1 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
