import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible';
import axios from './request/index'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import { Carousel,CarouselItem,Popover } from 'element-ui';
import { Lazyload } from 'vant';
import { WOW } from 'wowjs';

Vue.prototype.$wow = new WOW({
  boxClass: 'wow',
  animateClass:'animated',
  offset:50,
  mobile:true,
  live:false,
})

Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Popover);
Vue.use(Lazyload);
Vue.config.productionTip = false
if (process.env.NODE_ENV === 'production') {
  // 重写 console 的方法
  console.log = console.warn = console.error = function() {};
}
Vue.use(Vant);
new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
